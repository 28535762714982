import 'moment/min/locales';
import arrayMove from 'array-move';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import shuffle from 'lodash/shuffle';
import uniqBy from 'lodash/uniqBy';

import { DATE_LANGUAGE_MAP, PERCENTAGE_IMPACT, TEMPLATES } from '/imports/generator/api/constants';
import { getDefaultLanguage } from '/lib/helpers';
import { isDescriptionEmpty } from '/imports/pdf/core/api/helpers';
import { apolloClient as client } from '/lib/initApollo';
import { UPDATE_BLOCK_FIELD, REMOVE_BLOCK_ITEM } from 'imports/generator/api/apollo/client/mutations';
import shortid from 'shortid';
import { v4 as uuidv4 } from 'uuid';

export const displayName = (firstName, lastName, fallback = '') => {
  let res = '';
  if (!firstName && !lastName) {
    return fallback;
  } else if (firstName && !lastName) {
    res = firstName;
  } else if (!firstName && lastName) {
    res = lastName;
  } else {
    res = `${firstName} ${lastName}`;
  }
  return res;
};

const MMM_YYYY_REGEX = /^(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC) \d{4}/;

export const displayDate = (date, format = 'MMM YYYY', language = getDefaultLanguage(), t) => {
  // Some unfortunate users have corrupted data in their resumes.
  if (date === null || date === 'Invalid date') return t ? t('generator.unknown') : 'unknown';

  // Checks if a date matches MMM YYYY format
  if (MMM_YYYY_REGEX.test(date) || /^[0-9]{4}$/.test(date)) {
    return date;
  }

  const dt = moment.utc(date);
  const finalDate = dt.isValid() ? dt.locale(DATE_LANGUAGE_MAP[language] || language).format(format) : null;
  return typeof date === 'string' || typeof date === 'object' ? finalDate : date;
};

export const sortObjectListByArraySequence = (objectToSort, sequenceOrder = []) => {
  const sortedKeys = Object.keys(objectToSort).sort((a, b) => {
    const indexA = sequenceOrder.indexOf(a);
    const indexB = sequenceOrder.indexOf(b);
    // If both keys are in sortOrder, sort based on their index
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    // If only one key is in sortOrder, prioritize it
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }
    // If neither key is in sortOrder, keep their original order
    return 0;
  });
  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = objectToSort[key];
  });
  return sortedObject;
};

// Adjusts timezone offset:
// We need to adjust the offset time of dates
// before feeding to react-datepicker
// because the way the component's been set up
// it selects the last day of the month, at 11pm UTC time
// by default - a bit of a wanky thing but the developers don't
// seem to be interested in changing this functionality
export const adjustTimezoneOffset = (date = new Date()) => {
  return date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const displayTitle = (s1, s2, join, t) => {
  let res = '';
  if (!s1 && !s2) {
    res = `(${t('generator.form.not_specified')})`;
  } else if (s1 && !s2) {
    res = s1;
  } else if (!s1 && s2) {
    res = s2;
  } else {
    res = `${s1}${join}${s2}`;
  }
  return res;
};

export const displayTemplateTitle = (s1, s2, join) => {
  let res = '';
  if (!s1 && !s2) {
    res = '';
  } else if (s1 && !s2) {
    res = s1;
  } else if (!s1 && s2) {
    res = s2;
  } else {
    res = `${s1}${join}${s2}`;
  }
  return res;
};

export const emptyItem = (type, field) => {
  switch (type) {
    case 'COURSES':
      const course = field.course || '';
      const institution = field.institution || '';
      return !course.trim() && !institution.trim();
    case 'SKILLS':
    case 'SOFT_SKILLS':
    case 'HARD_SKILLS':
    case 'CUSTOM_SKILLS_CATEGORY':
      const skill = field.skill || '';
      return !skill.trim();
    case 'LANGUAGES':
      const languages = field.language || '';
      return !languages.trim();
    default:
      return false;
  }
};

export const isCourseEmpty = (field) => {
  const course = field.course || '';
  const institution = field.institution || '';
  return !course.trim() && !institution.trim();
};

export const displayDateRange = (startDate, endDate, current, hideStartDate, t, language) => {
  if (hideStartDate) return current ? t('present') : displayDate(endDate, undefined, language, t);
  return `${displayDate(startDate, undefined, language, t)} - ${
    current ? t('present') : displayDate(endDate, undefined, language, t)
  }`;
};

/**
 * focus element by selector
 * @param selector String
 * @param cs [String] - contentSelector
 */
export const focusElement = (selector, cs) => {
  const element = (() => {
    if (cs) {
      // querySelector throws error when id starts with digit
      const block = cs.includes('#') ? document.getElementById(cs.slice(1)) : document.querySelector(cs);
      if (!block) {
        return;
      }
      return block.querySelector('input') || block.querySelector(`${selector} textarea`);
    }
    if (selector.match(/name/)) {
      return document.querySelector(selector);
    }
    const lastItem = last(document.querySelectorAll(`${selector} [data-item-cont]`));
    return (lastItem && lastItem.querySelector('input')) || document.querySelector(`${selector} textarea`);
  })();
  if (element) element.focus();
};

export const needPopup = (resume, { step, intro }) => {
  if (!['start', 'experience', 'education', 'skills', 'summary'].some((item) => item === step) || intro) return false;

  if (step === 'summary') {
    const professionalSummary = resume.blocks.find((block) => block.type === 'PROFESSIONAL_SUMMARY');

    return (
      !professionalSummary.items ||
      !professionalSummary.items[0].fields ||
      isDescriptionEmpty(professionalSummary.items[0].fields.description)
    );
  }

  const { blocks } = resume;
  const type = step === 'experience' ? 'EMPLOYMENT' : step.toUpperCase();
  const block = blocks.find((b) => b.type === type);
  if (!block) return false;
  const min = step === 'experience' ? 1 : 0;
  return !(block.items && block.items.length > min);
};

export const randomizeTemplates = (TEMPLATES, FAKE_TEMPLATES, START = [], END = []) => {
  const FIRST = TEMPLATES.filter((template) => START.includes(template.id)).sort(
    (a, b) => START.indexOf(a.id) - START.indexOf(b.id),
  );
  const LAST = TEMPLATES.filter((template) => END.includes(template.id)).sort(
    (a, b) => END.indexOf(b.id) - END.indexOf(a.id),
  );

  const SHUFFLE = shuffle(
    TEMPLATES.filter((template) => !START.includes(template.id) && !END.includes(template.id)).concat(FAKE_TEMPLATES),
  );

  return uniqBy(FIRST.concat(SHUFFLE.concat(LAST)), (template) => template.name);
};

export const getColor = (percentage) => {
  if (percentage >= 70) return 'success';
  else if (percentage >= 30 && percentage < 70) return 'warning';
  return 'danger';
};

const getPercentageByIndex = (type, index, weights) => {
  if (index + 1 > weights.length) return last(weights);
  return weights[index];
};

const getTotalBlockPercentage = (type, length, weights) => {
  return Array(length)
    .fill()
    .reduce((res, _, i) => res + getPercentageByIndex(type, i, weights), 0);
};

export const getPercentage = (resume) => {
  let percentage = 10;
  if (resume.details.professionalSummary) {
    percentage += PERCENTAGE_IMPACT.professionalSummary;
  }
  if (resume.details.title) {
    percentage += PERCENTAGE_IMPACT.title;
  }
  Object.entries(PERCENTAGE_IMPACT.blocks).forEach(([type, weights]) => {
    const addedToResume = resume.blocks.find((b) => b.type === type);
    if (!addedToResume) return;
    const itemsLength = (addedToResume.items || []).length;
    if (!itemsLength) return;
    percentage += getTotalBlockPercentage(type, itemsLength, weights);
  });
  return percentage > 100 ? 100 : percentage;
};

const sortSuggestions = (a, b) => {
  if (a.percentage > b.percentage) return -1;
  else if (a.percentage < b.percentage) return 1;
  else {
    const keys = Object.keys(PERCENTAGE_IMPACT.blocks);
    if (keys.indexOf(a.name) > keys.indexOf(b.name)) return -1;
    else if (keys.indexOf(a.name) > keys.indexOf(b.name)) return 1;
    return 0;
  }
};

export const getSuggestions = (resume) => {
  const res = [];
  if (!resume.details.professionalSummary) {
    res.push({
      name: 'professionalSummary',
      percentage: PERCENTAGE_IMPACT.professionalSummary,
    });
  }
  if (!resume.details.title) {
    res.push({ name: 'title', percentage: PERCENTAGE_IMPACT.title });
  }
  Object.entries(PERCENTAGE_IMPACT.blocks).forEach(([type, weights]) => {
    const addedToResume = resume.blocks.find((block) => block.type === type);
    if (!addedToResume) {
      res.push({
        name: type,
        percentage: weights[0],
      });
    } else if (addedToResume.items) {
      res.push({
        name: type,
        percentage: getPercentageByIndex(type, addedToResume.items.length, weights),
      });
    } else {
      res.push({
        name: type,
        percentage: getPercentageByIndex(type, 0, weights),
      });
    }
  });
  return res.sort(sortSuggestions).slice(0, 6);
};

export const templateHasColor = (template, color) => TEMPLATES.find((t) => t.id === template).colors.includes(color);

export const sortBlocks = (blocks) => {
  const colGroup = groupBy(blocks, (b) => b.position[0]);
  for (const [colIndex, colBlocks] of Object.entries(colGroup)) {
    // Order columns so that truthy fixedPosition blocks are first,
    // and then order normally by Y position.
    const ordered = orderBy(colBlocks, ['fixedPosition', 'position'], ['desc', 'asc']).map((b, i) => {
      // Redo position Y numbers from zero to wipe out inconsistencies
      b.position[1] = i;
      return b;
    });

    // Replace blocks with ordered blocks
    colGroup[colIndex] = ordered;
  }

  return flatMap(colGroup);
};

export const HIDDEN_TEMPLATES = ['amsterdam', 'berlin'];

export const temporaryTemplateHandler = async (id, template, mutation) => {
  if (!HIDDEN_TEMPLATES.includes(template)) return;

  await mutation({
    variables: {
      docId: id,
      path: 'settings.template',
      value: 'budapest',
    },
  });
};

export const getBlockTranslationSlug = (blockName = '') => {
  switch (blockName.toLowerCase()) {
    case 'Websites & Social Links'.toLowerCase():
      return 'generator.final.websites_social_links';
    case 'Custom Skills Category Section'.toLowerCase():
      return 'custom_skill_category';
    case 'Skills'.toLowerCase():
      return 'skills';
    case 'Soft Skills'.toLowerCase():
      return 'soft_skills';
    case 'Hard Skills'.toLowerCase():
      return 'hard_skills';
    case 'Employment history'.toLowerCase():
      return 'generator.final.employement_history';
    case 'Education'.toLowerCase():
      return 'education';
    case 'Extra-curricular Activities'.toLowerCase():
      return 'extra_curricular_activities';
    case 'Languages'.toLowerCase():
      return 'languages';
    case 'Internships'.toLowerCase():
      return 'internships';
    case 'Custom section'.toLowerCase():
      return 'generator.final.custom_section';
    case 'References'.toLowerCase():
      return 'references';
    case 'Hobbies'.toLowerCase():
      return 'hobbies';
    case 'Courses'.toLowerCase():
      return 'courses';
    case 'Professional Summary'.toLowerCase():
      return 'generator.final.professional_summary';
    case 'Driving License'.toLowerCase():
      return 'driving_license';
    case 'Publications'.toLowerCase():
      return 'generator.publications';
    case 'Personal Details'.toLowerCase():
      return 'personal_details';
  }
};

export const getSkillsLevelSlug = (skill) => {
  switch (skill) {
    case 'Novice':
      return 'generator.skills.level_novice';
    case 'Beginner':
      return 'generator.skills.level_beginner';
    case 'Skillful':
      return 'generator.skills.level_skillful';
    case 'Experienced':
      return 'generator.skills.level_experienced';
    case 'Expert':
      return 'generator.skills.expert';
  }
};

export const updateImmutableDataSSR = (immutableResume, serverResume) => {
  const directUpdate = localStorage.getItem('resume-parse') || localStorage.getItem('email-update');
  const serverlanguage = serverResume.settings.language;
  const localLanguage = immutableResume.settings.language;
  const isLangUpdate = serverResume.settings.isLangUpdated;

  if (isLangUpdate && serverlanguage !== localLanguage) {
    return { ...serverResume };
  }
  if (directUpdate && (serverResume.fromScan || serverResume.fromLinkedIn)) {
    localStorage.removeItem(serverResume.fromScan ? 'resume-parse' : 'email-update');
    return { ...serverResume };
  }
  const immutableSummary = immutableResume.blocks.find((k) => k.type === 'PROFESSIONAL_SUMMARY');
  const serverSummary = serverResume.blocks.find((d) => d.type === 'PROFESSIONAL_SUMMARY');
  if (immutableSummary && serverSummary) immutableSummary.fixedPosition = serverSummary.fixedPosition;
  return {
    ...immutableResume,
    steps: serverResume.steps,
    currentStep: serverResume.currentStep,
    blocks: serverResume.blocks.length > immutableResume.blocks.length ? serverResume.blocks : immutableResume.blocks,
  };
};

export const updateCoverLetterImutableDataSSR = (localCoverLetter, serverCoverLetter) => {
  if (serverCoverLetter?.details?.isAICoverLetter) {
    return { ...serverCoverLetter };
  }
  return { ...localCoverLetter };
};

export const convertEndDate = (endDate) => {
  if (!endDate) return '';
  return new Date(endDate.toString()).toISOString();
};

export const hasArabicDescription = (resume) => {
  // unicode range of arabic characters - REGEX
  const arabicRegex = /[\u0600-\u06FF]/;
  for (const block of resume?.blocks) {
    if (!block || !block.items) {
      continue;
    }
    for (const item of block?.items) {
      // Check if description has Arabic characters
      if (arabicRegex.test(item?.fields?.description)) {
        return true;
      }
    }
  }

  return (
    arabicRegex.test(resume.details.title) ||
    arabicRegex.test(resume.details.firstName) ||
    arabicRegex.test(resume.details.lastName)
  );
};

export const closestToAverage = (numbers, averageInt) => {
  // Calculate the average of the array
  const average = averageInt ? averageInt : numbers.reduce((sum, num) => sum + num, 0) / numbers.length;

  // Find the element closest to the average
  let closestElement;

  for (let i = 0; i < numbers.length; i++) {
    if (!!numbers[i] && average > numbers[i] && (!closestElement || closestElement < numbers[i])) {
      const difference = Math.abs(numbers[i] - average);
      closestElement = numbers[i];
    }
  }

  return closestElement;
};

export const getSortedBlock = (blocks, position) => {
  //get blocks within a same group
  const groupBlock = blocks && blocks?.length ? blocks?.filter((block) => block.position[0] === position) : [];

  let importanceBlocks = [];
  let otherBlocks = [];
  groupBlock.map((b) => {
    if (!!b?.importance) importanceBlocks.push(b);
    else otherBlocks.push(b);
  });

  // sort blocks according to inportance
  const sortedBlock =
    importanceBlocks && importanceBlocks?.length ? importanceBlocks.sort((a, b) => a.importance - b.importance) : [];
  const otherSortedBlocks = otherBlocks && otherBlocks?.length ? otherBlocks.sort((a, b) => b?.height - a?.height) : [];
  const finalSortedBlocks = [...sortedBlock, ...otherSortedBlocks];
  const finalBlock =
    finalSortedBlocks && finalSortedBlocks?.length
      ? finalSortedBlocks?.map((block, index) => {
          block.position = [position, index];
          return block;
        })
      : [];
  return finalBlock;
};

export const findSubarrayWithClosestSum = (array, target) => {
  // Helper function to generate all possible combinations of subarrays
  function generateSubarrays(arr, index, current, result) {
    if (index === arr.length) {
      result.push(current.slice());
      return;
    }

    current.push(arr[index]);
    generateSubarrays(arr, index + 1, current, result);
    current.pop();
    generateSubarrays(arr, index + 1, current, result);
  }

  // Helper function to calculate the sum of an array
  function arraySum(arr) {
    return arr.reduce((sum, num) => sum + num, 0);
  }

  // Generate all possible combinations of subarrays
  const subarrays = [];
  generateSubarrays(array, 0, [], subarrays);

  // Find the subarray with the sum closest to the target
  let closestSum = Infinity;
  let closestSubarray = [];

  for (const subarray of subarrays) {
    const sum = arraySum(subarray);
    const diff = Math.abs(target - sum);
    if (diff < Math.abs(target - closestSum)) {
      closestSum = sum;
      closestSubarray = subarray;
    }
  }

  return closestSubarray;
};

export const TEMPLATE_DEFAULT_HEIGHT = {
  budapest: {
    left: 0,
    right: 4,
  },
  'budapest-v2': {
    left: 0,
    right: 4,
  },
  rotterdam: {
    left: 3,
    right: 2,
  },
  chicago: {
    left: 4,
    right: 0,
  },
  prague: {
    left: 0,
    right: 0,
  },
  riga: {
    left: 0,
    right: 0,
  },
  sydney: {
    left: 4,
    right: 0,
  },
  perth: {
    left: 0,
    right: 0,
  },
  vladivostok: {
    left: 10,
    right: 0,
  },
  montecarlo: {
    left: 4,
    right: 0,
  },
  kiev: {
    left: 10,
    right: 0,
  },
  sf: {
    left: 8,
    right: 0,
  },
  shanghai: {
    left: 0,
    right: 0,
  },
};

export const BLOCKS_IMPORTANCE = {
  PROFESSIONAL_SUMMARY: 1,
  EMPLOYMENT: 2,
  EDUCATION: 3,
  SKILLS: 4,
  HARD_SKILLS: 5,
  SOFT_SKILLS: 6,
  SOCIAL_LINKS: 7,
  LANGUAGES: 8,
  HOBBIES: 9,
};

const handlesortBlocks = (blocks = []) => {
  // Group blocks into columns
  const colGroup = groupBy(blocks, (b) => b.position[0]);
  for (const [colIndex, colBlocks] of Object.entries(colGroup)) {
    // Order columns so that truthy fixedPosition blocks are first,
    // and then order normally by Y position.
    const ordered = orderBy(colBlocks, ['fixedPosition', 'position'], ['desc', 'asc'])
      .sort((a, b) => a?.position[1] - b?.position[1])
      .map((b, i) => {
        // Redo position Y numbers from zero to wipe out inconsistencies
        b.position[1] = i;
        return b;
      });

    // Replace blocks with ordered blocks
    colGroup[colIndex] = ordered;
  }

  return flatMap(colGroup);
};

const blockSortedBlock = (blocks, position) => {
  //get blocks within a same group

  const groupBlock = blocks && blocks?.length ? blocks?.filter((block) => block.position[0] === position) : [];

  let importanceBlocks = [];
  let otherBlocks = [];
  groupBlock.map((b) => {
    if (!!b?.importance) importanceBlocks.push(b);
    else otherBlocks.push(b);
  });

  // sort blocks according to inportance
  const sortedBlock =
    importanceBlocks && importanceBlocks?.length ? importanceBlocks.sort((a, b) => a.importance - b.importance) : [];
  const otherSortedBlocks = otherBlocks && otherBlocks?.length ? otherBlocks.sort((a, b) => b?.height - a?.height) : [];
  const finalSortedBlocks = [...sortedBlock, ...otherSortedBlocks];
  const finalBlock =
    finalSortedBlocks && finalSortedBlocks?.length
      ? finalSortedBlocks?.map((block, index) => {
          block.position = [position, index];
          return block;
        })
      : [];

  return finalBlock;
};

const moveBlockToSpecificPosition = (blockId, blocks = [], position = [], blockType) => {
  const block = !!blockType ? blocks.find((b) => b.type === blockType) : blocks.find((b) => b.id === blockId);
  if (block && !block.fixedPosition && position.length) {
    const [xPosition, yPosition] = position;
    const [oldXPosition, oldYPosition] = block.position;

    // update the column based on xPosition
    block.position[0] = Math.max(0, xPosition);

    // if block have been added from another column
    if (xPosition !== oldXPosition) {
      // gets all the blocks in new column and which comes after the new position
      const newColumnBlocks = blocks.filter((b) => b.position[0] === xPosition && b.position[1] >= yPosition);
      // Swap all the blocks with 1 down
      newColumnBlocks.forEach((b) => {
        b.position[1] = Math.max(0, b.position[1] + 1);
      });
      block.position[1] = Math.max(0, yPosition);

      // get all old column blocks
      const oldColumnBlocks = blocks.filter((b) => b.position[0] === oldXPosition);
      oldColumnBlocks.sort((a, b) => {
        const firstElementPositionY = a.position[1];
        const secondElementPositionY = a.position[1];
        if (firstElementPositionY < secondElementPositionY) {
          return -1;
        }
        if (firstElementPositionY > secondElementPositionY) {
          return 1;
        }
        return 0;
      });
      oldColumnBlocks.forEach((b, index) => {
        b.position[1] = index;
      });
    } else {
      const existingColumnBlocks = blocks.filter((b) => b.position[0] === xPosition);
      existingColumnBlocks.sort((a, b) => {
        const firstValue = a.position[1];
        const secondValue = b.position[1];
        if (firstValue < secondValue) {
          return -1;
        }
        if (firstValue > secondValue) {
          return 1;
        }
        return 0;
      });
      arrayMove.mutate(existingColumnBlocks, oldYPosition, yPosition);
      existingColumnBlocks.forEach((b, index) => {
        b.position[1] = index;
      });
    }
  }
  return handlesortBlocks(blocks);
};

export const BLOCK_STEP = {
  experience: 'EMPLOYMENT',
  education: 'EDUCATION',
  skills: 'SKILLS',
  summary: 'PROFESSIONAL_SUMMARY',
};

export const handleBlockRepositioning = (updateBlocks, heights, currentBlock, addedNewBlock, isFinalStep) => {
  let blocks = [...updateBlocks];
  if (!blocks || !blocks.length || !heights) return { blocks };

  blocks = blocks.map((block) => {
    if (block?.type === 'CUSTOM' || (block?.type === 'CUSTOM_SKILLS_CATEGORY' && block?.title !== 'HOBBIES')) {
      let customBlockHeight = heights[block?.type]?.find((b) => b?.blockId === block?.id);
      block.height = customBlockHeight?.height || 0;
    } else {
      block.height = heights[block?.type] || 0;
    }
    block.importance = BLOCKS_IMPORTANCE[block?.type];
    return block;
  });
  // filter out left & right block from the given blocks
  let leftBlock = blocks.filter((b) => b.position?.[0] === 0);
  let rightBlock = blocks.filter((b) => b.position?.[0] === 1);
  const getHeightArray = (blocks, typeArr) => {
    if (!blocks || !blocks?.length) return [];
    return blocks
      .filter((b) => !typeArr?.includes(b?.type))
      .map((b) => ({ height: b.height, id: b?.id, type: b?.type }));
  };

  const getTotalHeight = (arr, cur = 0) => {
    if (!arr?.length) return 0;
    if (typeof arr[0] === 'object') {
      const heights = getHeightArray(arr);
      return heights?.length ? heights.reduce((i, c = 0) => (c = i + c), cur || 0) : 0;
    }
    return arr.reduce((i, c = 0) => (c = i + c), cur || 0);
  };

  // find fix elements for group
  let fixElements = blocks.filter((b) => b.fixedPosition || b?.type === 'EMPLOYMENT');

  const educationBlock = blocks?.find((b) => b?.type === 'EDUCATION');
  if (educationBlock?.items?.length > 1) fixElements.push(educationBlock);

  let leftItemsHeight = getHeightArray(leftBlock);
  let rightItemsHeight = getHeightArray(rightBlock);

  const defaultLeftHeight = +heights?.USER_DETAILS_LEFT + +heights?.CONTACT_DETAIL;

  const defaultRightHeight = +heights?.USER_DETAILS;

  // calculate height of left & right group

  const getCloset = (arr, value) => {
    const heightArr = (arr || []).map((b) => b?.height);
    let closest = closestToAverage(heightArr, value);
    const ele = arr.find((b) => b?.height === closest);
    const isFixedElement = fixElements.find((f) => f.type === ele?.type);
    if (isFixedElement && ele) {
      return getCloset(
        arr.filter((a) => a.type !== ele.type),
        value,
      );
    }
    return ele;
  };

  const getBlocksArray = (leftArrTemp, rightArrTemp) => {
    let leftArr = [...leftArrTemp];
    let rightArr = [...rightArrTemp];
    const leftHeightArr = leftArr?.map((b) => b?.height);
    const rightHeightArr = rightArr?.map((b) => b?.height);
    const leftHeight = leftHeightArr?.reduce((i, c) => (c = c + (i || 0)), defaultLeftHeight);
    const rightHeight = rightHeightArr?.reduce((i, c) => (c = c + (i || 0)), defaultRightHeight);

    if (leftHeight > rightHeight) {
      let ele = getCloset(
        leftArr.map((e) => {
          const isSkills = e?.type === 'SKILLS';
          if (isSkills) {
            e.height = e.height / 2;
          }
          return e;
        }),
        Math.abs(leftHeight - rightHeight),
      );

      if (ele) {
        rightArr.push(ele);
        leftArr = leftArr.filter((a) => a?.type !== ele?.type);
        return getBlocksArray(leftArr, rightArr);
      } else return { leftArr, rightArr };
    } else if (rightHeight > leftHeight) {
      const ele = getCloset(
        rightArr.map((e) => {
          const isSkills = e?.type === 'SKILLS';
          if (isSkills) {
            e.height = e.height * 2;
          }
          return e;
        }),
        Math.abs(leftHeight - rightHeight),
      );
      if (ele) {
        leftArr.push(ele);
        rightArr = rightArr.filter((a) => a?.type !== ele?.type);
        return getBlocksArray(leftArr, rightArr);
      } else return { leftArr, rightArr };
    } else return { leftArr, rightArr };
  };

  const { leftArr, rightArr } = getBlocksArray(leftItemsHeight, rightItemsHeight);

  if ((!!currentBlock && !!addedNewBlock) || (!!currentBlock && !currentBlock.height)) {
    let leftBlock = blocks.filter((b) => b.position[0] === 0);
    let rightBlock = blocks.filter((b) => b.position[0] === 1);
    // calculate height of left & right group
    let leftHeight = getTotalHeight(leftBlock, defaultLeftHeight);
    let rightHeight = getTotalHeight(rightBlock, defaultRightHeight);
    if (rightHeight > leftHeight) {
      blocks = moveBlockToSpecificPosition(
        currentBlock?.id,
        blocks,
        [0, leftBlock.length],
        !currentBlock?.id ? currentBlock?.type : null,
      );
    } else {
      blocks = moveBlockToSpecificPosition(
        currentBlock?.id,
        blocks,
        [1, rightBlock.length],
        !currentBlock?.id ? currentBlock?.type : null,
      );
    }
  }

  const leftId = leftArr?.map((b) => b?.id);
  const rightId = rightArr?.map((b) => b?.id);

  leftBlock = blocks?.filter((b) => leftId.includes(b?.id));
  leftBlock = leftBlock.map((b, index) => ({ ...b, position: [0, index] }));
  rightBlock = blocks?.filter((b) => rightId?.includes(b?.id));
  rightBlock = rightBlock.map((b, index) => ({ ...b, position: [1, index] }));

  leftBlock = blockSortedBlock(leftBlock, 0);
  rightBlock = blockSortedBlock(rightBlock, 1);

  blocks = [...leftBlock, ...rightBlock];
  return { blocks };
};

export const replacedBlocks = (resume, updatedBlocks) => {
  resume.blocks = updatedBlocks;
  return resume;
};

export const extractBlockInfo = (blocks) => {
  if (!blocks.length > 0) return [];
  const blockInfoArray = [];
  blocks.forEach((block) => {
    const blockInfo = {
      blockId: block.id,
      position: block.position,
    };
    blockInfoArray.push(blockInfo);
  });
  return blockInfoArray;
};

export const determineMovementDirection = (source, destination) => {
  // Check for invalid input
  if (!source || !destination) {
    return 'Invalid input';
  }
  const { droppableId: sourceDroppableId, index: sourceIndex } = source;
  const { droppableId: destinationDroppableId, index: destinationIndex } = destination;
  // Determine horizontal movement
  if (sourceDroppableId !== destinationDroppableId) {
    return destinationDroppableId === 'left' ? 'LEFT' : 'RIGHT';
  }
  // Determine vertical movement within the same droppableId
  if (sourceIndex !== destinationIndex) {
    return destinationIndex > sourceIndex ? 'DOWN' : 'TOP';
  }
  // If there's no movement
  return 'No movement';
};

export const getInputName = (fieldName, variables) => {
  return variables?.itemId ? `${variables?.itemId?.substr(0, 5)}${fieldName}` : fieldName;
};

export const getIsAgent = () => {
  const isAgent = typeof localStorage !== 'undefined' && localStorage.getItem('isAgent');
  return isAgent;
};

export const mkSkill = (skill, skillLevel, order) => {
  let skillItem = {
    id: uuidv4(),
    animationKey: shortid.generate(),
    fields: {
      skill: skill,
      skillLevel: skillLevel,
    },
    order: order,
  };
  return skillItem;
};

export const addSkillsToResume = (resume, skills, updateImmue, putOnTop = false) => {
  const skillsBlock = resume.blocks.find((block) => block.type == 'SKILLS');
  let orderStart = 0;
  let itemsWithoutTypename = [];
  if (skillsBlock.items) {
    itemsWithoutTypename = skillsBlock.items.map((item) => {
      let newItem = { ...item };
      delete newItem.__typename;
      return newItem;
    });

    if (!putOnTop) orderStart = skillsBlock.items.length;
  }

  const skillItemsToAdd = skills.map((skill) => {
    let skillItem = mkSkill(skill, 'EXPERT', orderStart);
    orderStart++;
    return skillItem;
  });

  if (putOnTop) {
    // shift existing items down
    itemsWithoutTypename = itemsWithoutTypename.map((item) => {
      item.order = item.order + skillItemsToAdd.length;
      return item;
    });
  }

  // FE update
  skillsBlock.items = [...itemsWithoutTypename, ...skillItemsToAdd];
  updateImmue(resume);

  // BE update
  return client.mutate({
    mutation: UPDATE_BLOCK_FIELD,
    variables: {
      docId: resume.id,
      blockId: skillsBlock.id,
      field: 'items',
      value: [...itemsWithoutTypename, ...skillItemsToAdd],
    },
  });
};

export const removeSkillFromResume = (resume, skill, updateImmue) => {
  const skillsBlock = resume.blocks.find((block) => block.type == 'SKILLS');
  const skillItem = skillsBlock.items.find((item) => item.fields.skill == skill);
  if (!skillItem) return;
  //FE update
  skillsBlock.items = skillsBlock.items.filter((item) => item.fields.skill !== skill);
  // update order of items having order greater than the removed item
  skillsBlock.items.forEach((item) => {
    if (item.order > skillItem.order) {
      item.order--;
    }
  });
  updateImmue(resume);

  // BE update
  return client.mutate({
    mutation: REMOVE_BLOCK_ITEM,
    variables: {
      resumeId: resume.id,
      blockId: skillsBlock.id,
      itemId: skillItem.id,
    },
  });
};

export const CLICK_TO_EDIT_EXP_NEW_VERSION = 4;
