// A4 Dimensions at 96 PPI.
export const A4_WIDTH = 794;
export const A4_HEIGHT = 1123;

export const COVER_LETTER_SOURCE = 'cover-letter';
export const RESUME_SOURCE = 'resume';

export const getPaddingMargin = (scale, { left = 0, right = 0, top = 0, bottom = 0 }, unit = 'px') => {
  return `${top * scale}${unit} ${right * scale}${unit} ${bottom * scale}${unit} ${left * scale}${unit}`;
};

export const convertPx = (scale, original, unit = 'px', rounded = false) => {
  const scaledValue = original * scale;
  const normalized = scaledValue < 0 ? Math.min(-1, scaledValue) : Math.max(1, scaledValue);
  const value = rounded ? Math.round(normalized) : normalized;
  return `${value}${unit}`;
};

export const convertPt = (scale, original) => {
  return `${original * scale}pt`;
};

export const convertNumber = (scale, original) => {
  return scale * original;
};

export const TEMPLATES_FONTS = {
  raleway: {
    fontName: 'Raleway',
    regular: 'Raleway',
    bold: 'Raleway Bold',
    italic: 'Raleway Italic',
    boldItalic: 'Raleway BoldItalic',
    semiBold: 'Raleway SemiBold',
    medium: 'Raleway Medium',
    light: 'Raleway Light',
  },
  lato: {
    fontName: 'Lato',
    regular: 'Lato',
    bold: 'Lato Bold',
    italic: 'Lato Italic',
    boldItalic: 'Lato BoldItalic',
    medium: 'Lato Medium',
    semiBold: 'Lato SemiBold',
    light: 'Lato Light',
    black: 'Lato Black',
  },
  arial: {
    fontName: 'Arial',
    regular: 'Arial',
    bold: 'Arial Bold',
    italic: 'Arial Italic',
    boldItalic: 'Arial BoldItalic',
    medium: 'Arial Medium',
    semiBold: 'Arial Medium', //Medium instead SemiBold
    light: 'Arial Light',
  },
  crimsonText: {
    fontName: 'Crimson Text',
    regular: 'CrimsonText',
    bold: 'CrimsonText Bold',
    italic: 'CrimsonText Italic',
    boldItalic: 'CrimsonText BoldItalic',
    semiBold: 'CrimsonText SemiBold',
    roman: 'Crimson Roman',
    semiBoldItalic: 'CrimsonText SemiBoldItalic',
    medium: 'CrimsonText SemiBold', //SemiBold instead Medium
    light: 'CrimsonText', //Regular instead light
  },
  butler: {
    fontName: 'Butler',
    regular: 'Butler Regular',
    bold: 'Butler Bold',
    medium: 'Butler Medium',
    semiBold: 'Butler Medium', //Medium instead SemiBold
    italic: 'Butler Regular; font-style: italic',
    boldItalic: 'Butler Regular; font-style: italic; font-weight: 600',
    light: 'Butler Light',
  },
  playfairDisplay: {
    fontName: 'Playfair Display',
    regular: 'PlayfairDisplay',
    bold: 'PlayfairDisplay Bold',
    medium: 'PlayfairDisplay Medium',
    semiBold: 'PlayfairDisplay SemiBold',
    italic: 'PlayfairDisplay Italic',
    boldItalic: 'PlayfairDisplay BoldItalic',
    light: 'PlayfairDisplay', //Regular instead light
  },
  kelsonSans: {
    fontName: 'Kelson Sans',
    regular: 'KelsonSans',
    bold: 'KelsonSans Bold',
    italic: 'KelsonSans; font-style: italic',
    boldItalic: 'KelsonSans; font-style: italic; font-weight: 600',
    medium: 'KelsonSans', //Regular instead Medium
    semiBold: 'KelsonSans', //Regular instead SemiBold
    light: 'KelsonSans Light',
  },
  bebasNeue: {
    fontName: 'Bebas Neue',
    regular: 'BebasNeue',
    bold: 'BebasNeue; font-weight: 600',
    italic: 'BebasNeue; font-style: italic',
    boldItalic: 'BebasNeue; font-style: italic; font-weight: 600',
    medium: 'BebasNeue', //Regular instead Medium
    semiBold: 'BebasNeue', //Regular instead SemiBold
    light: 'BebasNeue', //Regular instead Light
  },
  montserrat: {
    fontName: 'Montserrat',
    regular: 'Montserrat',
    bold: 'Montserrat Bold',
    boldItalic: 'Montserrat BoldItalic',
    medium: 'Montserrat Medium',
    semiBold: 'Montserrat SemiBold',
    light: 'Montserrat Light',
    italic: 'Montserrat Italic',
  },
  roboto: {
    fontName: 'Roboto',
    regular: 'Roboto',
    bold: 'Roboto Bold',
    italic: 'Roboto Italic',
    boldItalic: 'Roboto BoldItalic',
    medium: 'Roboto Medium',
    semiBold: 'Roboto Medium', //Medium instead SemiBold
    black: 'Roboto Black',
    light: 'Roboto Light',
  },
  openSans: {
    fontName: 'Open Sans',
    regular: 'OpenSans',
    bold: 'OpenSans Bold',
    italic: 'OpenSans Italic',
    boldItalic: 'OpenSans BoldItalic',
    semiBold: 'OpenSans SemiBold',
    medium: 'OpenSans SemiBold', //SemiBold instead Medium
    light: 'OpenSans Light',
  },
  ubuntu: {
    fontName: 'Ubuntu',
    regular: 'Ubuntu',
    bold: 'Ubuntu Bold',
    italic: 'Ubuntu Italic',
    boldItalic: 'Ubuntu BoldItalic',
    medium: 'Ubuntu Medium',
    semiBold: 'Ubuntu Medium', //Medium instead SemiBold
    light: 'Ubuntu Light',
  },
  sourceSansPro: {
    fontName: 'Source Sans Pro',
    regular: 'SourceSansPro',
    bold: 'SourceSansPro Bold',
    italic: 'SourceSansPro Italic',
    boldItalic: 'SourceSansPro BoldItalic',
    semiBold: 'SourceSansPro SemiBold',
    medium: 'SourceSansPro SemiBold', //SemiBold instead Medium
    light: 'SourceSansPro Light',
  },
  nunito: {
    fontName: 'Nunito',
    regular: 'Nunito',
    bold: 'Nunito Bold',
    italic: 'Nunito Italic',
    boldItalic: 'Nunito BoldItalic',
    semiBold: 'Nunito SemiBold',
    medium: 'Nunito SemiBold', //SemiBold instead Medium
    light: 'Nunito Light',
  },
  lora: {
    fontName: 'Lora',
    regular: 'Lora',
    bold: 'Lora Bold',
    italic: 'Lora Italic',
    boldItalic: 'Lora BoldItalic',
    medium: 'Lora Medium',
    semiBold: 'Lora SemiBold',
    light: 'Lora', //Regular instead Light
  },
  ibmPlexSans: {
    fontName: 'IBM Plex Sans',
    regular: 'IBMPlexSans',
    bold: 'IBMPlexSans Bold',
    italic: 'IBMPlexSans Italic',
    boldItalic: 'IBMPlexSans BoldItalic',
    medium: 'IBMPlexSans Medium',
    semiBold: 'IBMPlexSans SemiBold',
    light: 'IBMPlexSans Light',
  },
  exo: {
    fontName: 'Exo',
    regular: 'Exo',
    bold: 'Exo Bold',
    italic: 'Exo Italic',
    boldItalic: 'Exo BoldItalic',
    medium: 'Exo Medium',
    semiBold: 'Exo SemiBold',
    light: 'Exo Light',
  },
  helvetica: {
    fontName: 'Helvetica',
    regular: 'Helvetica',
    bold: 'Helvetica Bold',
    italic: 'Helvetica Italic',
    boldItalic: 'Helvetica BoldItalic',
    medium: 'Helvetica', //Regular instead Medium
    semiBold: 'Helvetica', //Regular instead SemiBold
    light: 'Helvetica Light',
  },
  firaSans: {
    fontName: 'Fira Sans',
    regular: 'FiraSans',
    bold: 'FiraSans Bold',
    italic: 'FiraSans Italic',
    boldItalic: 'FiraSans BoldItalic',
    medium: 'FiraSans Medium',
    semiBold: 'FiraSans SemiBold',
    light: 'FiraSans Light',
  },
  bebasKai: {
    fontName: 'Bebas Kai',
    regular: 'BebasKai',
    bold: 'BebasKai; font-weight: 600',
    italic: 'BebasKai; font-style: italic',
    boldItalic: 'BebasKai; font-style: italic; font-weight: 600',
    medium: 'BebasKai', //Regular instead Medium
    semiBold: 'BebasKai', //Regular instead SemiBold
    light: 'BebasKai', //Regular instead Light
  },
  aller: {
    fontName: 'Aller',
    regular: 'Aller',
    bold: 'Aller Bold',
    italic: 'Aller Italic',
    boldItalic: 'Aller BoldItalic',
    medium: 'Aller', //Regular instead Medium
    semiBold: 'Aller', //Regular instead SemiBold
    light: 'Aller Light',
  },
  calibri: {
    fontName: 'Calibri',
    regular: 'Calibri',
    bold: 'Calibri Bold',
    italic: 'Calibri Italic',
    boldItalic: 'Calibri BoldItalic',
    medium: 'Calibri', //Regular instead Medium
    semiBold: 'Calibri', //Regular instead SemiBold
    light: 'Calibri Light',
  },
  workSans: {
    fontName: 'Work Sans',
    regular: 'WorkSans',
    bold: 'WorkSans Bold',
    italic: 'WorkSans Italic',
    boldItalic: 'WorkSans BoldItalic',
    medium: 'WorkSans Medium',
    semiBold: 'WorkSans SemiBold',
    light: 'WorkSans Light',
  },
  robotoMono: {
    fontName: 'Roboto Mono',
    regular: 'RobotoMono',
    bold: 'RobotoMono Bold',
    italic: 'RobotoMono Italic',
    boldItalic: 'RobotoMono BoldItalic',
    medium: 'RobotoMono Medium',
    semiBold: 'RobotoMono SemiBold',
    light: 'RobotoMono Light',
  },
  comfortaa: {
    fontName: 'Comfortaa',
    regular: 'Comfortaa',
    bold: 'Comfortaa Bold',
    medium: 'Comfortaa Medium',
    semiBold: 'Comfortaa SemiBold',
    italic: 'Comfortaa; font-style: italic',
    boldItalic: 'Comfortaa; font-style: italic; font-weight: 600',
    light: 'Comfortaa Light',
  },
  rubik: {
    fontName: 'Rubik',
    regular: 'Rubik',
    bold: 'Rubik Bold',
    italic: 'Rubik Italic',
    boldItalic: 'Rubik BoldItalic',
  },
};

export const TEMPLATES_FONTS_ARABIK = {
  rubik: {
    fontName: 'Rubik',
    regular: 'Rubik',
    bold: 'Rubik Bold',
    italic: 'Rubik Italic',
    boldItalic: 'Rubik BoldItalic',
  },
  amiri: {
    fontName: 'Amiri',
    regular: 'Amiri',
    bold: 'Amiri Bold',
    italic: 'Amiri Italic',
    boldItalic: 'Amiri BoldItalic',
  },
  lateef: {
    fontName: 'Lateef',
    regular: 'Lateef',
    bold: 'Lateef Bold',
    italic: 'Lateef Italic',
    boldItalic: 'Lateef BoldItalic',
  },
  tajawal: {
    fontName: 'Tajawal',
    regular: 'Tajawal',
    bold: 'Tajawal Bold',
    italic: 'Tajawal Italic',
    boldItalic: 'Tajawal BoldItalic',
  },
};

export const DEFAULT_TEMPLATE_FONTS_ARABIC = {
  arabicHeadingFont: 'rubik',
  arabicContentFont: 'rubik',
};

export const DEFAULT_TEMPLATE_FONTS = {
  budapest: {
    headingFont: 'bebasNeue',
    contentFont: 'lato',
  },
  'budapest-v2': {
    headingFont: 'bebasNeue',
    contentFont: 'lato',
  },
  sydney: {
    headingFont: 'raleway',
    contentFont: 'raleway',
  },
  chicago: {
    headingFont: 'raleway',
    contentFont: 'raleway',
  },
  prague: {
    headingFont: 'raleway',
    contentFont: 'lato',
  },
  riga: {
    headingFont: 'montserrat',
    contentFont: 'crimsonText',
  },
  sf: {
    headingFont: 'roboto',
    contentFont: 'raleway',
  },
  montecarlo: {
    headingFont: 'raleway',
    contentFont: 'arial',
  },
  kiev: {
    headingFont: 'arial',
    contentFont: 'arial',
  },
  perth: {
    headingFont: 'raleway',
    contentFont: 'raleway',
  },
  vladivostok: {
    headingFont: 'lato',
    contentFont: 'lato',
  },
  shanghai: {
    headingFont: 'kelsonSans',
    contentFont: 'lato',
  },
  rotterdam: {
    headingFont: 'lato',
    contentFont: 'lato',
  },
  atsresume: {
    headingFont: 'rubik',
    contentFont: 'rubik',
  },
};

export const WIDE_FONTS = ['robotoMono'];

export const SKILL_PERCENTAGE_MAP = {
  NOVICE: 20,
  BEGINNER: 40,
  SKILLFUL: 60,
  EXPERIENCED: 80,
  EXPERT: 100,
};

export const LANGUAGE_PERCENTAGE_MAP = {
  NATIVE: 100,
  PROFICIENT: 80,
  VERY_GOOD: 60,
  GOOD: 40,
  WORKING: 20,
  C2: 100,
  C1: 100,
  B2: 80,
  B1: 60,
  A2: 40,
  A1: 20,
};

export const UPDATED_FONTS_TEMPLATE = ['budapest', 'montecarlo', 'sydney', 'chicago', 'prague', 'perth', 'shanghai'];

export const SPLIT_SKILLS_BLOCK = ['SOFT_SKILLS', 'HARD_SKILLS'];

export const HIDE_BLOCKS_NON_UPLOAD = ['SOFT_SKILLS', 'HARD_SKILLS'];

export const OLD_SYSTEM = ['SOFT_SKILLS', 'HARD_SKILLS', 'CUSTOM_SKILLS_CATEGORY'];

export const SKILLS_BLOCKS = ['SOFT_SKILLS', 'HARD_SKILLS', 'CUSTOM_SKILLS_CATEGORY', 'SKILLS'];
