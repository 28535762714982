import { BREAKPOINTS } from '/styles/variables';
import { min, max, between, getCurrentBreakpoint, larger, medium, smaller } from '/styles/mixins';

/**
 * had to use single imports for the above 'cause of random errors
 * that impacted both UX and code bundling
 * regarding undefined variables exported in /imports/core/ui/helpers.
 *
 * It needs a bit of investigation and definitely some refactoring
 *
 * */

const SHADOW_COLOR = 'rgba(30, 30, 30, 0.07)';
const SHADOW_COLOR_ALTERNATE = 'rgba(23, 31, 38, 0.1)';
const BASE_FONT_SIZE = '16px';

export const theme = {
  breakpoints: BREAKPOINTS,
  colors: {
    primary: '#0099da',
    secondary: '#37c26e',
    white: '#ffffff',
    black: '#282b32',
    black2: '#202323',
    black3: '#202023',
    primary2: '#4c7cf7',
    none: '#282b32',
    violet: '#673fb4',
    blue: '#0099da',
    blue2: '#1688FE',
    blueLighter: '#2274b0',
    lightBlue: '#f6f7fa',
    green: '#37c26e',
    red: '#fc4f52',
    darkBlack: '#171717',
    orange: '#e5891E',
    darkOrange: '#e5661E',
    gray: {
      darker: '#3c414b',
      darker2: '#4C4C55',
      dark: '#58606e',
      regular: '#98A1B3',
      regular2: '#737980',
      light: '#bfc5d6',
      light2: '#fafafa',
      lighter: '#f2f5fa',
      lighter2: '#f1f4f9',
      placeholder: '#a3a3a3',
      previewBackground: '#7a8599;',
      subHeading: '#c2c4cc',
    },
    danger: '#fc4f52',
    warning: '#ff9159',
    yellow: '#f3b721',
    info: '#6654ff',
    lightGreen: '#80cc14',
    success: '#2ba763',
    gold: '#b58e58',
    blockItemBorder: '#dde3f0',
    previewBlack: '#0f141f',
    cta: '#2196f3',
    ctaPreview: '#0099da',
    ctaPreviewHover: '#008bc6',
    ctaPreviewClick: '#007cb1',
    budapestViolet: '#583193',
    budapestGreen: '#63a143',
    budapestRed: '#c0202b',
    budapestBlue: '#2c2e81',
    rigaGold: '#af9661',
    fairPink: '#f2e1d9',
    lightGrey: '#d6d6d6',
    charcoal: '#484848',
    sherpaBlue: '#065154',
    frenchPass: '#b0d1d7',
    vladivostokDarkBlue: '#021924',
    vladivostokLightPink: '#F2DBE0',
    vladivostokDarkGrey: '#474646',
    vladivostokLightBlue: '#B0D2D8',
    vladivostokLightGrey: '#D7D7D7',
    vladivostokBlack: '#1A1A19',
    shanghaiBlack: '#414042',
    shanghaiDarkGray: 'rgb(65,64,66)',
    shanghaiLightGray: '#bcbec0',
    shanghaiViolet: '#103E67',
    shanghaiGreen: '#2E996C',
    shanghaiPink: '#CE5E89',
    shanghaiRed: '#B9192F',
    shanghaiBlue: '#2068A2',
    rotterdamBlack: '#000000',
    rotterdamLightGreen: '#C1E2E0',
    rotterdamPink: '#EAD6DB',
    rotterdamPurple: '#D8D9EC',
    rotterdamGreen: '#CDDFC3',
    rotterdamOrange: '#E2BFB0',
    rotterdamGray: '#434244',
    harvard: {
      v1: { title: '#f3f3f5' },
      v2: { title: '#3a66e6' },
      v3: { title: '#fa6827' },
      v4: { title: '#c19b76' },
      v5: { title: '#a3a5ad' },
      v6: { title: '#53d1a0' },
      v7: { title: '#161617' },
    },
    stanford: {
      v1: { title: '#407eca', text: '#fff' },
      v2: { title: '#407eca', text: '#fff' },
      v3: { title: '#407eca', text: '#fff' },
      v4: { title: '#407eca', text: '#fff' },
      v5: { title: '#407eca', text: '#fff' },
      v6: { title: '#407eca', text: '#fff' },
      v7: { title: '#407eca', text: '#fff' },
      v8: { title: '#407eca', text: '#fff' },
    },
  },
  general: {
    gridSize: 12,
    gridGap: '18px',
    borderRadius: '4px',
    mobilePadding: '5vw',
    borderWidth: '1px',
    headerHeight: '75px',
    containerWidth: {
      md: '980px',
      lg: '1200px',
    },
    resumeItemHeight: {
      lg: '420px',
      md: '390px',
      sm: '350px',
      xs: '150px',
    },
    previewWidth: {
      md: '720px',
      lg: '720px',
    },
  },
  modal: {
    backdropColor: 'rgba(30, 30, 30, .85)',
  },
  transitions: {
    fast: '.2s ease',
    medium: '.4s ease',
    slow: '.8s ease',
  },
  zIndex: {
    header: 1000,
    headerFixed: 1030,
    modalBg: 1040,
    modal: 10000,
    confirmBg: 20000,
    confirm: 20001,
    popover: 1060,
    tooltip: 1070,
  },
  spaces: {
    unit: `calc(${BASE_FONT_SIZE} / 2)`,
  },
  shadows: {
    level1: `0 7px 12px ${SHADOW_COLOR}`,
    level2: `0 3px 40px ${SHADOW_COLOR}`,
    level3: `0 27px 45px ${SHADOW_COLOR}`,
    level4: `0px 15px 30px ${SHADOW_COLOR_ALTERNATE}`,
  },
  font: {
    size: {
      base: BASE_FONT_SIZE,
      h1: '32px',
      h2: '28px',
      h3: '24px',
      h4: '18px',
      h5: '16px',
      h6: '14px',
      small: '12px',
      smaller: '10px',
    },
    lineHeight: {
      base: 1.38,
      reduced: 1.2,
      increased: 1.5,
      double: 2,
    },
    letterSpacing: {
      base: 'normal',
      accent: '0.2em',
    },
    family: {
      text: 'TTComommons, Roboto light, Helvetica Neue, sans-serif',
      header: 'TTComommons Bold, Arial Rounded, Helvetica Neue, sans-serif',
      accent: 'TTComommons DemiBold, Arial Rounded, Helvetica Neue, sans-serif',
      correctText: 'TTCommons, Roboto light, Helvetica Neue, sans-serif',
      correctHeader: 'TTCommons DemiBold, Arial Rounded, Helvetica Neue, sans-serif',
      correctAccent: 'TTCommons DemiBold, Arial Rounded, Helvetica Neue, sans-serif',
      wizardHeader: 'Montserrat Bold, TTCommons Bold, Arial Rounded, Helvetica Neue, sans-serif',
      wizardSubheader: 'Montserrat,TTCommons, Roboto light, Helvetica Neue, sans-serif',
      tahoma: 'Tahoma',
      openSans: 'Open Sans',
      arial: 'Arial',
      systemFont: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
      'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji',
      'Segoe UI Symbol'`,
    },
  },
  min,
  max,
  between,
  getCurrentBreakpoint,
  larger,
  medium,
  smaller,
};

export default theme;
