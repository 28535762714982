import arabicDegree from '/imports/generator/api/data/arabicDegree';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

export const LANGUAGE_DEAR_GENDER = [
  'de',
  'fr',
  'es',
  'ru',
  'it',
  'pt',
  'pt-br',
  'he',
  'uk',
  'sr',
  'sl',
  'bg',
  'sk',
  'lt',
  'cs',
  'ca',
  'pl',
  'ar',
];

export const TEMPLATES = [
  {
    name: 'Riga',
    id: 'riga',
    image: 'riga',
    defaultColor: 'black',
    colors: ['black', 'rigaGold'],
  },
  {
    name: 'Monte Carlo',
    id: 'montecarlo',
    hiddenOnSlider: true,
    accentsHidden: true,
    defaultColor: 'black',
    colors: ['green', 'blue', 'red', 'violet', 'black'],
  },
  {
    name: 'Sydney',
    id: 'sydney',
    noUserPic: true,
    accentsHidden: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'Kiev',
    id: 'kiev',
    hiddenOnSlider: true,
    accentsHidden: false,
    defaultColor: 'fairPink',
    colors: ['fairPink', 'lightGrey', 'charcoal', 'frenchPass', 'sherpaBlue'],
  },
  {
    name: 'Chicago',
    id: 'chicago',
    image: 'chicago',
    accentsHidden: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'Prague',
    id: 'prague',
    accentsHidden: true,
    noUserPic: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'Perth',
    id: 'perth',
    accentsHidden: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'San Francisco',
    id: 'sf',
    accentsHidden: true,
    hiddenOnSlider: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'Budapest',
    id: 'budapest',
    image: 'budapest',
    defaultColor: 'black',
    colors: ['black', 'budapestViolet', 'budapestBlue', 'budapestGreen', 'budapestRed'],
  },
  {
    name: 'Budapest 2',
    id: 'budapest-v2',
    image: 'budapest',
    displayCondition: () => {
      return marvelEmitter.getActiveVariant('exp_template_budapest_v2') == 'with_budaptest_v2';
    },
    hiddenOnSlider: true,
    defaultColor: 'black',
    colors: ['black', 'budapestViolet', 'budapestBlue', 'budapestGreen', 'budapestRed'],
  },
  {
    name: 'ATSResume',
    id: 'atsresume',
    image: 'ATSResume',
    role: ['admin', 'agent'],
    defaultColor: 'black',
    colors: ['black'],
  },
  {
    name: 'Vladivostok',
    id: 'vladivostok',
    defaultColor: 'vladivostokBlack',
    accentsHidden: false,
    photoCropSize: 280,
    hiddenOnSlider: true,
    colors: [
      'vladivostokBlack',
      'vladivostokLightGrey',
      'vladivostokLightBlue',
      'vladivostokDarkGrey',
      'vladivostokLightPink',
      'vladivostokDarkBlue',
    ],
  },
  {
    name: 'Shanghai',
    id: 'shanghai',
    image: 'shanghai',
    hiddenOnSlider: true,
    accentsHidden: false,
    defaultColor: 'shanghaiBlack',
    colors: ['shanghaiBlack', 'shanghaiViolet', 'shanghaiBlue', 'shanghaiGreen', 'shanghaiRed', 'shanghaiPink'],
  },
  {
    name: 'Rotterdam',
    id: 'rotterdam',
    image: 'rotterdam',
    accentsHidden: false,
    defaultColor: 'rotterdamOrange',
    colors: [
      'rotterdamGray',
      'rotterdamLightGreen',
      'rotterdamPink',
      'rotterdamPurple',
      'rotterdamGreen',
      'rotterdamOrange',
      'rotterdamBlack',
    ],
  },
];

export const WEBSITE_TEMPLATES = [
  { name: 'Cambridge', id: 'cambridge', image: 'cambridge' },
  { name: 'Oxford', id: 'oxford', image: 'oxford' },
  { name: 'Harvard', id: 'harvard', image: 'harvard' },
];

export const FAKE_TEMPLATES = [];

// Featured templates in the slider
// Last in array is the left-most
// First is the one in middle
// Second is the right-most
export const FEATURED_TEMPLATES = ['budapest', 'chicago', 'rotterdam'];

export const FEATURED_TEMPLATES_NEW = ['chicago', 'budapest', 'rotterdam'];

export const FEATURED_WEBSITE_TEMPLATES = ['cambridge', 'harvard', 'oxford'];

// The messages for the template ids in the selected variant array
export const FEATURED_TEMPLATES_MESSAGES = ['Most Selected', 'Recommended', 'New'];

export const NEW_FEATURED_TEMPLATES_MESSAGES = ['Recommended', 'Most Selected', 'New'];

export const FEATURED_WEBSITE_TEMPLATE_MESSAGES = ['New', 'Recommended', 'Most Selected'];

export const REQUIRED_BLOCKS = ['EDUCATION', 'SKILLS', 'EMPLOYMENT', 'SOCIAL_LINKS', 'PROFESSIONAL_SUMMARY'];

export const LANGUAGE_LEVEL_OPTIONS = [
  { title: 'Select level', value: '', alt: '', titleSlug: 'generator.language_select_level' },
  { title: 'Superior/Native', value: 'C2', alt: 'NATIVE', titleSlug: 'generator.language_superior' },
  { title: 'Advanced High', value: 'C1', alt: 'PROFICIENT', titleSlug: 'generator.language_high' },
  { title: 'Advanced Mid', value: 'B2', alt: 'VERY_GOOD', titleSlug: 'generator.language_mid' },
  { title: 'Advanced Low', value: 'B1', alt: 'GOOD', titleSlug: 'generator.language_low' },
  { title: 'Intermediate', value: 'A2', alt: 'WORKING', titleSlug: 'generator.language_intermediate' },
  { title: 'Novice', value: 'A1', alt: '', titleSlug: 'generator.skills.level_novice' },
];

export const BLOCK_NAMES = {
  CUSTOM: 'Custom section',
  CUSTOM_SKILLS_CATEGORY: 'Custom Skills Category Section',
  COURSES: 'Courses',
  EXTRA_CURRICULAR: 'Extra-curricular Activities',
  INTERNSHIPS: 'Internships',
  HOBBIES: 'Hobbies',
  LANGUAGES: 'Languages',
  REFERENCES: 'References',
  EMPLOYMENT: 'Employment history',
  SKILLS: 'Skills',
  SOFT_SKILLS: 'Soft Skills',
  HARD_SKILLS: 'Hard Skills',
  EDUCATION: 'Education',
  SOCIAL_LINKS: 'Social links',
  DRIVING_LICENSE: 'Driving License',
  PUBLICATIONS: 'Publications',
  PERSONAL_DETAILS: 'Personal Details',
};

export const BLOCK_KEYS = {
  COURSES: 'courses',
  EXTRA_CURRICULAR: 'extra_curricular_activities',
  INTERNSHIPS: 'internships',
  HOBBIES: 'hobbies',
  LANGUAGES: 'languages',
  REFERENCES: 'references',
  EMPLOYMENT: 'work_experience',
  SKILLS: 'skills',
  SOFT_SKILLS: 'soft_skills',
  HARD_SKILLS: 'hard_skills',
  EDUCATION: 'education',
  SOCIAL_LINKS: 'generator.final.links',
  DRIVING_LICENSE: 'driving_license',
  PUBLICATIONS: 'publications',
  PERSONAL_DETAILS: 'personal_details',
  PROFESSIONAL_SUMMARY: 'about_me',
};

export const BLOCK_KEYS_SINGULAR = {
  COURSES: 'course',
  INTERNSHIPS: 'internship',
  LANGUAGES: 'languages',
  REFERENCES: 'reference',
  SKILLS: 'skills',
  EDUCATION: 'education',
  SOFT_SKILLS: 'soft_skills',
  HARD_SKILLS: 'hard_skills',
};

export const PERCENTAGE_IMPACT = {
  professionalSummary: 10,
  title: 8,
  blocks: {
    REFERENCES: [7, 0],
    EMPLOYMENT: [7, 4, 3, 2, 1],
    EDUCATION: [7, 4, 3, 2, 1],
    SKILLS: [6, 3, 2, 1],
    SOFT_SKILLS: [6, 3, 2, 1],
    HARD_SKILLS: [6, 3, 2, 1],
    CUSTOM_SKILLS_CATEGORY: [6, 3, 2, 1],
    COURSES: [4, 2, 1],
    EXTRA_CURRICULAR: [4, 2, 1],
    LANGUAGES: [4, 4, 3, 2, 1],
  },
};

export const A4_RATIO = 1.4142;

export const A4_FACTOR = 0.71;

export const PDF_LOADER =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSI1NzIiIGhlaWdodD0iNzg2IiB2aWV3Qm94PSIwIDAgNTcyIDc4NiI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNiAwaDU2MGE2IDYgMCAwIDEgNiA2djc3NGE2IDYgMCAwIDEtNiA2SDZhNiA2IDAgMCAxLTYtNlY2YTYgNiAwIDAgMSA2LTZ6bTU4IDI3NHYxNGg0NDR2LTE0SDY0em0wIDMwdjE0aDQ0NHYtMTRINjR6bTAgMzB2MTRoNDQ0di0xNEg2NHptMCAzMHYxNGgyMDR2LTE0SDY0em0wLTIyNnYxNGg0NDR2LTE0SDY0em0wIDMwdjE0aDQ0NHYtMTRINjR6bTAgMzB2MTRoNDQ0di0xNEg2NHptMCAzMHYxNGg0NDR2LTE0SDY0ek0yMzYgODZ2MTJoMTAwVjg2SDIzNnptLTUwLTM2djI0aDIwMFY1MEgxODZ6Ii8+PC9zdmc+Cg==';

export const FORM_LOADER =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSI3MjAiIGhlaWdodD0iMTA0NCIgdmlld0JveD0iMCAwIDcyMCAxMDQ0Ij48cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zODAgMTk5djEyaDgwdi0xMmgtODB6TTAgMGg3MjB2MTA0NEgwVjB6bTUyIDg0M2EyIDIgMCAwIDAtMiAydjE0NmEyIDIgMCAwIDAgMiAyaDYxNmEyIDIgMCAwIDAgMi0yVjg0NWEyIDIgMCAwIDAtMi0ySDUyem0tMi0yMHYxMmg4MHYtMTJINTB6bTAtMzZ2MjBoMTYwdi0yMEg1MHptMC02MnYxMmg4MHYtMTJINTB6bTItMjYyYTIgMiAwIDAgMC0yIDJ2NDJhMiAyIDAgMCAwIDIgMmgyODZhMiAyIDAgMCAwIDItMnYtNDJhMiAyIDAgMCAwLTItMkg1MnptLTItMjB2MTJoODB2LTEySDUwem0yIDExNmEyIDIgMCAwIDAtMiAydjQyYTIgMiAwIDAgMCAyIDJoMjg2YTIgMiAwIDAgMCAyLTJ2LTQyYTIgMiAwIDAgMC0yLTJINTJ6bS0yLTIwdjEyaDgwdi0xMkg1MHptMiAxMTZhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDUyem0tMi0yMHYxMmg4MHYtMTJINTB6bTMzMi0xNzJhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDM4MnptLTItMjB2MTJoODB2LTEyaC04MHptMiAxMTZhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDM4MnptLTItMjB2MTJoODB2LTEyaC04MHptMiAxMTZhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDM4MnptLTItMjB2MTJoODB2LTEyaC04MHpNNTAgNDA1djIwaDE2MHYtMjBINTB6bTItMTg2YTIgMiAwIDAgMC0yIDJ2NDJhMiAyIDAgMCAwIDIgMmgyODZhMiAyIDAgMCAwIDItMnYtNDJhMiAyIDAgMCAwLTItMkg1MnptLTItMjB2MTJoODB2LTEySDUwem0zMzIgMjBhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDM4MnpNNTIgMzE1YTIgMiAwIDAgMC0yIDJ2NDJhMiAyIDAgMCAwIDIgMmgyODZhMiAyIDAgMCAwIDItMnYtNDJhMiAyIDAgMCAwLTItMkg1MnptLTItMjB2MTJoODB2LTEySDUwem0zMzIgMjBhMiAyIDAgMCAwLTIgMnY0MmEyIDIgMCAwIDAgMiAyaDI4NmEyIDIgMCAwIDAgMi0ydi00MmEyIDIgMCAwIDAtMi0ySDM4MnptLTItMjB2MTJoODB2LTEyaC04MHpNNTAgMTI4djI0aDYyMHYtMjRINTB6bTI2MC00NHYxMmgxMDBWODRIMzEwem0tNTAtMzZ2MjRoMjAwVjQ4SDI2MHoiLz48L3N2Zz4K';

export const SYNCING_URL =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5zeW5jaW5nPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9InN5bmNpbmciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0xNywxMiBDMTcsOS4yMzg1NzYyNSAxNC43NjE0MjM3LDcgMTIsNyBDOS4yMzg1NzYyNSw3IDcsOS4yMzg1NzYyNSA3LDEyIEM3LDE0Ljc2MTQyMzcgOS4yMzg1NzYyNSwxNyAxMiwxNyBDMTIuNTUyMjg0NywxNyAxMywxNy40NDc3MTUzIDEzLDE4IEMxMywxOC41NTIyODQ3IDEyLjU1MjI4NDcsMTkgMTIsMTkgQzguMTM0MDA2NzUsMTkgNSwxNS44NjU5OTMyIDUsMTIgQzUsOC4xMzQwMDY3NSA4LjEzNDAwNjc1LDUgMTIsNSBDMTUuODY1OTkzMiw1IDE5LDguMTM0MDA2NzUgMTksMTIgQzE5LDEyLjU1MjI4NDcgMTguNTUyMjg0NywxMyAxOCwxMyBDMTcuNDQ3NzE1MywxMyAxNywxMi41NTIyODQ3IDE3LDEyIFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==';

export const SYNCED_URL =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5zeW5jZWQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0ic3luY2VkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNNiwxOC45Nzc1Nzg1IEMzLjE5Njc0OTk5LDE4LjcyNDk2NDEgMSwxNi4zNjkwMjEzIDEsMTMuNSBDMSwxMC40NjMwNDYyIDMuNDYxNDQxMjcsOC4wMDA5OTI3MyA2LjQ5ODE2MzIyLDguMDAwMDAwMyBDNy40Nzc3MjI3OCw1LjY1MTA0ODI5IDkuNzk2MDQ5ODksNCAxMi41LDQgQzE1LjkzMDMxNDEsNCAxOC43Mzk5OTc0LDYuNjU3MjM2NDkgMTguOTgyOTUzMSwxMC4wMjU2MTI3IEMyMS4yNDExMjk3LDEwLjI2NjU1OTYgMjMsMTIuMTc3ODc2NyAyMywxNC41IEMyMywxNi44MTYyNzY4IDIxLjI0OTk3NTIsMTguNzIzODEzNSAxOSwxOC45NzI1MzU2IEwxOSwxOSBMNiwxOSBMNiwxOC45Nzc1Nzg1IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iIzBGMTQxRiIgb3BhY2l0eT0iMC4yMzk5OTk5OTUiPjwvcGF0aD4KICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgtMyIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHBvaW50cz0iOSAxMS41IDExLjUgMTQgMTUgMTAiPjwvcG9seWxpbmU+CiAgICA8L2c+Cjwvc3ZnPg==';

export const BLOCK_ADD_ITEM_NAMES = {
  CUSTOM: 'generator.add_item',
  COURSES: 'generator.add_course',
  EXTRA_CURRICULAR: 'generator.add_activity',
  INTERNSHIPS: 'generator.add_internship',
  HOBBIES: 'hobby',
  LANGUAGES: 'generator.add_language',
  REFERENCES: 'generator.add_reference',
  EMPLOYMENT: 'generator.add_employment',
  SKILLS: 'generator.add_skill',
  SOFT_SKILLS: 'generator.add_skill',
  HARD_SKILLS: 'generator.add_skill',
  CUSTOM_SKILLS_CATEGORY: 'generator.add_skill',
  EDUCATION: 'generator.add_education',
  SOCIAL_LINKS: 'generator.add_link',
  PUBLICATIONS: 'generator.add_publications',
  PERSONAL_DETAILS: 'personal_details',
  DRIVING_LICENSE: 'driving_license',
};

export const BLOCK_ADD_DESCRIPTION = {
  EMPLOYMENT: 'generator.experience_add_description',
  EDUCATION: 'generator.education_add_description',
  CUSTOM: 'generator.custom_section_add_description',
  EXTRA_CURRICULAR: 'generator.extra_curricular_activities_add_description',
  INTERNSHIPS: 'generator.experience_add_description',
};

export const SINGLE_ITEM_BLOCKS = ['HOBBIES', 'PROFESSIONAL_SUMMARY', 'DRIVING_LICENSE', 'PERSONAL_DETAILS'];

export const FIXED_SUMMARY_TEMPLATES = ['riga', 'prague'];

export const WIZARD_HELP_PHRASES = {
  summary: [
    // 'Provided onsite training.',
    // 'Installed, testes and serviced alarm systems, ensuring that they functioned correctly.',
    // 'Successfully led key projects which resulted in [positive outcome].',
    // 'Delivered an exceptional level of service to each customer by listening to concerns and answering questions.',
  ],
  education: [],
  employment: [],
  hobbies: [],
  internships: [],
  extra: [],
  custom: [],
};

export const PARSE_PARAMETERS = {
  budapest: {
    bold: 'Lato Bold',
    italic: 'Lato Italic',
    boldItalic: 'Lato BoldItalic',
  },
  'budapest-v2': {
    bold: 'Lato Bold',
    italic: 'Lato Italic',
    boldItalic: 'Lato BoldItalic',
  },
  sydney: {
    bold: 'Raleway Bold',
    italic: 'Raleway Italic',
    boldItalic: 'Raleway BoldItalic',
  },
  chicago: {
    bold: 'Raleway Bold',
    italic: 'Raleway Italic',
    boldItalic: 'Raleway BoldItalic',
  },
  prague: {
    bold: 'Raleway Bold',
    italic: 'Raleway Italic',
    boldItalic: 'Raleway BoldItalic',
  },
  riga: {
    bold: 'CrimsonText Bold',
    italic: 'CrimsonText Italic',
    boldItalic: 'CrimsonText BoldItalic',
  },
  sf: {
    bold: 'Roboto Bold',
    italic: 'Roboto Italic',
    boldItalic: 'Roboto BoldItalic',
  },
  montecarlo: {
    bold: 'Arial Bold',
    italic: 'Arial Italic',
    boldItalic: 'Arial BoldItalic',
  },
  shanghai: {
    bold: 'Lato Bold',
    italic: 'Lato Italic',
    boldItalic: 'Lato BoldItalic',
  },
  kiev: {
    bold: 'Arial Bold',
    italic: 'Arial Italic',
    boldItalic: 'Arial BoldItalic',
  },
  vladivostok: {
    bold: 'Lato Bold',
    italic: 'Lato Italic',
    boldItalic: 'Lato BoldItalic',
  },
  perth: {
    bold: 'Raleway Bold',
    italic: 'Raleway Italic',
    boldItalic: 'Raleway BoldItalic',
  },
  harvard: {
    bold: 'Gilroy Medium',
    italic: 'Gilroy Bold',
    boldItalic: 'Gilroy Bold',
  },
};

export const WIZARD_MODAL_DATA = {
  experience: {
    message:
      'Looks like you haven’t entered any past work experience. We recommend that you at least enter your past position and company.',
    messageSlug: 'generator.dialog.experience',
    actionSlug: 'generator.dialog.experience.action',
    actionText: 'I don’t have work experience',
  },
  experienceOne: {
    message: 'You’ve only listed one job. Do you want to add another position to your work experience?',
    messageSlug: 'generator.dialog.experience_one',
    actionSlug: 'generator.dialog.experience_one.action',
    actionText: 'Continue to Education',
  },
  education: {
    message:
      'Looks like you haven’t entered any education information. We recommend that you at least enter your past school and degree.',
    messageSlug: 'generator.dialog.education',
    actionSlug: 'generator.dialog.education.action',
    actionText: 'I don’t have any education',
  },
  skills: {
    message: 'Looks like you haven’t entered any skills. We recommend that you at least enter one skill.',
    messageSlug: 'generator.dialog.skills',
    actionSlug: 'generator.dialog.skills.action',
    actionText: 'I don’t have any skills',
  },
  summary: {
    message: 'Looks like you haven’t entered any summary.',
    messageSlug: 'generator.dialog.summary',
    actionSlug: 'generator.dialog.summary.action',
    actionText: 'I don’t want to do it now',
  },
};

export const DEFAULT_ITEMS = {
  CUSTOM() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  COURSES() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  EXTRA_CURRICULAR() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  INTERNSHIPS() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  EMPLOYMENT() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  EDUCATION() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  LANGUAGES() {
    return {
      languageLevel: 'WORKING',
    };
  },
  SKILLS() {
    return {
      skillLevel: 'EXPERT',
    };
  },
};

export const EMPTY_FIELDS = {
  city: null,
  company: null,
  course: null,
  current: null,
  degree: null,
  description: null,
  email: null,
  employer: null,
  endDate: null,
  fullName: null,
  hobbies: null,
  institution: null,
  label: null,
  language: null,
  languageLevel: null,
  phone: null,
  school: null,
  skill: null,
  skillLevel: null,
  startDate: null,
  title: null,
  url: null,
  hideDates: null,
  hideStartDate: null,
  drivingLicense: null,
  publicationArticleTitle: null,
  publicationTitle: null,
  publicationDate: null,
  dateOfBirth: null,
  nationality: null,
  visaStatus: null,
  maritalStatus: null,
};

export const SUGGESTIONS_NAMES = {
  ...BLOCK_NAMES,
  professionalSummary: 'professional summary',
  title: 'title',
};

export const COVER_LETTER_TEMPLATES = [
  {
    name: 'Budapest',
    id: 'budapest',
    defaultColor: 'black',
    colors: ['black', 'budapestViolet', 'budapestBlue', 'budapestGreen', 'budapestRed'],
  },
  {
    name: 'Monte Carlo',
    id: 'montecarlo',
    accentsHidden: true,
    defaultColor: 'black',
    colors: ['green', 'blue', 'red', 'violet', 'black'],
  },
  {
    name: 'Perth',
    id: 'perth',
    accentsHidden: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
  {
    name: 'Riga',
    id: 'riga',
    defaultColor: 'black',
    colors: ['black', 'rigaGold'],
  },
  {
    name: 'Kiev',
    id: 'kiev',
    accentsHidden: false,
    defaultColor: 'fairPink',
    colors: ['fairPink', 'lightGrey', 'charcoal', 'frenchPass', 'sherpaBlue'],
  },
  {
    name: 'Chicago',
    id: 'chicago',
    accentsHidden: true,
    colors: ['black', 'violet', 'blue', 'green', 'red'],
  },
];

// ISO 639-1 languages codes
export const LANGUAGES_CODES = {
  ENGLISH: 'en',
  SPANISH: 'es',
  FINNISH: 'fi',
  INDONESIAN: 'id',
  ROMANIAN: 'ro',
  HEBREW: 'he',
  ARMENIAN: 'hy',
  THAI: 'th',
  DANISH: 'da',
  GERMAN: 'de',
  CROATIAN: 'hr',
  DUTCH: 'nl',
  MODERN_STANDARD_ARAB: 'ar',
  GREEK: 'el',
  SWEDISH: 'sv',
  HUNGARIAN: 'hu',
  BRAZIL: 'pt-br',
  RUSSIAN: 'ru',
  UKRAINIAN: 'uk',
  GEORGIAN: 'ka',
  ITALIAN: 'it',
  TURKISH: 'tr',
  VIETNAMESE: 'vi',
  SERBIAN: 'sr',
  FRENCH: 'fr',
  ICELANDIC: 'is',
  SLOVENIAN: 'sl',
  BULGARIAN: 'bg',
  SLOVAK: 'sk',
  LATVIAN: 'lv',
  NORWEGIAN: 'no',
  POLISH: 'pl',
  ESTONIAN: 'et',
  CZECH: 'cs',
  LITHUANIAN: 'lt',
  PORTUGUESE: 'pt',
  MALAYSIAN: 'ms',
  JAPANESE: 'ja',
  KOREAN: 'ko',
  CHINESE: 'zh-cn',
  TAJIK: 'tj',
  AZERBAIJANI: 'az',
  CATALAN: 'ca',
  KAZAKH: 'kk',
  HINDI: 'hi',
  BENGALI: 'bn',
  UZBEK: 'uz',
  FILIPINO: 'tl',
};

export const ALL_LANGUAGES = [
  {
    title: 'English',
    value: LANGUAGES_CODES.ENGLISH,
    showOnCoverLetter: true,
  },
  {
    title: 'Serbian',
    value: LANGUAGES_CODES.SERBIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Spanish',
    value: LANGUAGES_CODES.SPANISH,
    showOnCoverLetter: true,
    countries: [
      'MX',
      'AR',
      'CO',
      'UY',
      'EC',
      'PY',
      'BO',
      'CL',
      'CR',
      'CU',
      'DO',
      'PA',
      'PE',
      'GQ',
      'SV',
      'GT',
      'HN',
      'NI',
      'VE',
    ],
  },
  {
    title: 'Malaysian',
    value: LANGUAGES_CODES.MALAYSIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Finnish',
    value: LANGUAGES_CODES.FINNISH,
    showOnCoverLetter: true,
  },
  {
    title: 'Indonesian',
    value: LANGUAGES_CODES.INDONESIAN,
    countries: ['ID'],
    showOnCoverLetter: true,
  },
  {
    title: 'Romanian',
    value: LANGUAGES_CODES.ROMANIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Hebrew',
    value: LANGUAGES_CODES.HEBREW,
    isRtl: true,
    showOnCoverLetter: true,
  },
  {
    title: 'Armenian',
    value: LANGUAGES_CODES.ARMENIAN,
  },
  {
    title: 'Thai',
    value: LANGUAGES_CODES.THAI,
    showOnCoverLetter: true,
  },
  {
    title: 'Danish',
    value: LANGUAGES_CODES.DANISH,
    showOnCoverLetter: true,
  },
  {
    title: 'Deutsch',
    value: LANGUAGES_CODES.GERMAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Croatian',
    value: LANGUAGES_CODES.CROATIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Dutch',
    value: LANGUAGES_CODES.DUTCH,
    showOnCoverLetter: true,
  },
  {
    title: 'Arabic',
    value: LANGUAGES_CODES.MODERN_STANDARD_ARAB,
    countries: ['BH', 'IQ', 'IL', 'JO', 'KW', 'OM', 'PS', 'LB', 'QA', 'SA', 'SY', 'AE', 'YE'],
    showOnCoverLetter: true,
    isRtl: true,
  },
  {
    title: 'Greek',
    value: LANGUAGES_CODES.GREEK,
    showOnCoverLetter: true,
  },
  {
    title: 'Swedish',
    value: LANGUAGES_CODES.SWEDISH,
    showOnCoverLetter: true,
  },
  {
    title: 'Hungarian',
    value: LANGUAGES_CODES.HUNGARIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Portuguese (Brazil)',
    value: LANGUAGES_CODES.BRAZIL,
    countries: ['AO', 'BR', 'MZ', 'GW', 'GQ', 'ST'],
    showOnCoverLetter: true,
  },
  {
    title: 'Portuguese (Portugal)',
    value: LANGUAGES_CODES.PORTUGUESE,
    showOnCoverLetter: true,
  },
  {
    title: 'Russian',
    value: LANGUAGES_CODES.RUSSIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Ukrainian',
    value: LANGUAGES_CODES.UKRAINIAN,
  },
  {
    title: 'Georgian',
    value: LANGUAGES_CODES.GEORGIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Italian',
    value: LANGUAGES_CODES.ITALIAN,
    countries: ['IT', 'SM', 'CH', 'VA'],
    showOnCoverLetter: true,
  },
  {
    title: 'Turkish',
    value: LANGUAGES_CODES.TURKISH,
    countries: ['TR', 'AZ', 'TM', 'KG', 'KZ', 'UZ'],
    showOnCoverLetter: true,
  },
  {
    title: 'Vietnamese',
    value: LANGUAGES_CODES.VIETNAMESE,
    showOnCoverLetter: true,
  },
  {
    title: 'French',
    value: LANGUAGES_CODES.FRENCH,
    showOnCoverLetter: true,
    countries: [
      'FR',
      'CA',
      'MG',
      'CM',
      'NE',
      'BF',
      'ML',
      'SN',
      'TD',
      'GN',
      'RW',
      'BE',
      'BI',
      'BJ',
      'HT',
      'CH',
      'TG',
      'CF',
      'CG',
      'GA',
      'GQ',
      'DJ',
      'KM',
      'LU',
      'VU',
      'SC',
      'MC',
    ],
  },
  {
    title: 'Icelandic',
    value: LANGUAGES_CODES.ICELANDIC,
    showOnCoverLetter: true,
  },
  {
    title: 'Slovenian',
    value: LANGUAGES_CODES.SLOVENIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Bulgarian',
    value: LANGUAGES_CODES.BULGARIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Slovak',
    value: LANGUAGES_CODES.SLOVAK,
  },
  {
    title: 'Latvian',
    value: LANGUAGES_CODES.LATVIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Norwegian',
    value: LANGUAGES_CODES.NORWEGIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Polish',
    value: LANGUAGES_CODES.POLISH,
    showOnCoverLetter: true,
  },
  {
    title: 'Estonian',
    value: LANGUAGES_CODES.ESTONIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Czech',
    value: LANGUAGES_CODES.CZECH,
    showOnCoverLetter: true,
  },
  {
    title: 'Lithuanian',
    value: LANGUAGES_CODES.LITHUANIAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Japanese',
    value: LANGUAGES_CODES.JAPANESE,
    showOnCoverLetter: true,
  },
  {
    title: 'Korean',
    value: LANGUAGES_CODES.KOREAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Chinese',
    value: LANGUAGES_CODES.CHINESE,
    showOnCoverLetter: true,
  },
  {
    title: 'Tajik',
    value: LANGUAGES_CODES.TAJIK,
    showOnCoverLetter: true,
  },
  {
    title: 'Azerbaijani',
    value: LANGUAGES_CODES.AZERBAIJANI,
    showOnCoverLetter: true,
  },
  {
    title: 'Catalan',
    value: LANGUAGES_CODES.CATALAN,
    showOnCoverLetter: true,
  },
  {
    title: 'Kazakh',
    value: LANGUAGES_CODES.KAZAKH,
    showOnCoverLetter: true,
  },
  {
    title: 'Hindi',
    value: LANGUAGES_CODES.HINDI,
    showOnCoverLetter: true,
  },
  {
    title: 'Bengali',
    value: LANGUAGES_CODES.BENGALI,
    showOnCoverLetter: true,
  },
  {
    title: 'Uzbek',
    value: LANGUAGES_CODES.UZBEK,
    showOnCoverLetter: true,
  },
  {
    title: 'Filipino',
    value: LANGUAGES_CODES.FILIPINO,
    showOnCoverLetter: true,
  },
];

// reduce letter spacing of blocks headings for below languages for Prague,Perth and chicago templates
export const SMALL_KERNING = [
  LANGUAGES_CODES.FRENCH,
  LANGUAGES_CODES.CROATIAN,
  LANGUAGES_CODES.ITALIAN,
  LANGUAGES_CODES.DANISH,
  LANGUAGES_CODES.DUTCH,
  LANGUAGES_CODES.SPANISH,
  LANGUAGES_CODES.BRAZIL,
  LANGUAGES_CODES.GERMAN,
  LANGUAGES_CODES.GREEK,
  LANGUAGES_CODES.GEORGIAN,
  LANGUAGES_CODES.HUNGARIAN,
  LANGUAGES_CODES.INDONESIAN,
  LANGUAGES_CODES.SERBIAN,
  LANGUAGES_CODES.SWEDISH,
  LANGUAGES_CODES.ICELANDIC,
  LANGUAGES_CODES.BULGARIAN,
  LANGUAGES_CODES.CZECH,
  LANGUAGES_CODES.ESTONIAN,
  LANGUAGES_CODES.SLOVAK,
  LANGUAGES_CODES.LITHUANIAN,
];

// extra reduce letter spacing of blocks headings for below languages for Prague,Perth and chicago templates
export const EXTRA_SMALL_KERNING = [
  LANGUAGES_CODES.ARMENIAN,
  LANGUAGES_CODES.FINNISH,
  LANGUAGES_CODES.SLOVENIAN,
  LANGUAGES_CODES.NORWEGIAN,
  LANGUAGES_CODES.POLISH,
];

export const RTLLanguages = [LANGUAGES_CODES.HEBREW, LANGUAGES_CODES.MODERN_STANDARD_ARAB];

export const AVAILABLE_PLATFORM_LANGUAGES = [
  LANGUAGES_CODES.BRAZIL,
  LANGUAGES_CODES.TURKISH,
  LANGUAGES_CODES.SPANISH,
  LANGUAGES_CODES.ITALIAN,
  LANGUAGES_CODES.INDONESIAN,
  LANGUAGES_CODES.MODERN_STANDARD_ARAB,
  LANGUAGES_CODES.HUNGARIAN,
  LANGUAGES_CODES.VIETNAMESE,
  LANGUAGES_CODES.GERMAN,
  LANGUAGES_CODES.DANISH,
  LANGUAGES_CODES.POLISH,
  LANGUAGES_CODES.SWEDISH,
  LANGUAGES_CODES.FINNISH,
  LANGUAGES_CODES.NORWEGIAN,
  LANGUAGES_CODES.DUTCH,
  LANGUAGES_CODES.GEORGIAN,
  LANGUAGES_CODES.RUSSIAN,
  LANGUAGES_CODES.GREEK,
  LANGUAGES_CODES.CZECH,
  LANGUAGES_CODES.THAI,
  LANGUAGES_CODES.LATVIAN,
  LANGUAGES_CODES.PORTUGUESE,
  LANGUAGES_CODES.ROMANIAN,
  LANGUAGES_CODES.LITHUANIAN,
  LANGUAGES_CODES.HEBREW,
  LANGUAGES_CODES.MALAYSIAN,
  LANGUAGES_CODES.ENGLISH,
  LANGUAGES_CODES.CROATIAN,
  LANGUAGES_CODES.ESTONIAN,
  LANGUAGES_CODES.SERBIAN,
  LANGUAGES_CODES.SLOVENIAN,
  LANGUAGES_CODES.JAPANESE,
  LANGUAGES_CODES.KOREAN,
  LANGUAGES_CODES.CHINESE,
  LANGUAGES_CODES.TAJIK,
  LANGUAGES_CODES.AZERBAIJANI,
  LANGUAGES_CODES.CATALAN,
  LANGUAGES_CODES.KAZAKH,
  LANGUAGES_CODES.HINDI,
  LANGUAGES_CODES.BENGALI,
  LANGUAGES_CODES.UZBEK,
  LANGUAGES_CODES.BULGARIAN,
  LANGUAGES_CODES.FILIPINO,
];

export const supported = (type, host) => {
  if (type.includes('all')) return true;
  return type.includes(host);
};

export const degreeForES = [
  { title: 'Bachillerato de Ciencias', value: 'Bachillerato de Ciencias' },
  { title: 'Bachillerato de Ciencias Sociales', value: 'Bachillerato de Ciencias Sociales' },
  { title: 'Bachillerato de Humanidades', value: 'Bachillerato de Humanidades' },
  { title: 'Bachillerato de Artes Plásticas, Imagen y Diseño', value: 'Bachillerato de Artes Plásticas' },
  {
    title: 'Bachillerato de Artes Escénicas, Música y Danza',
    value: 'Bachillerato de Artes Escénicas, Música y Danza',
  },
];

export const degreeForFR = [
  { title: 'Bac S', value: 'Bac S' },
  { title: 'Bac ES', value: 'Bac ES' },
  { title: 'Bac L', value: 'Bac L' },
  { title: 'Bac STI2D', value: 'Bac STI2D' },
  { title: 'Bac STHR', value: 'Bac STHR' },
  { title: 'Bac STMG', value: 'Bac STMG' },
  { title: 'Bac ST2S', value: 'Bac ST2S' },
  { title: 'Bac STD2A', value: 'Bac STD2A' },
  { title: 'Bac STL', value: 'Bac STL' },
  { title: 'Bac Pro Gestion-Administration', value: 'Bac Pro Gestion-Administration' },
  { title: 'Bac Pro Commerce', value: 'Bac Pro Commerce' },
  { title: 'Bac Pro Vente', value: 'Bac Pro Vente' },
  { title: 'Bac Pro ARCU', value: 'Bac Pro ARCU' },
  { title: 'Bac Pro Logistique', value: 'Bac Pro Logistique' },
  { title: 'Bac Pro MEI', value: 'Bac Pro MEI' },
  { title: 'Bac Pro ELEEC', value: 'Bac Pro ELEEC' },
  { title: 'Bac Pro MELEC', value: 'Bac Pro MELEC' },
  { title: 'Bac Pro SEN', value: 'Bac Pro SEN' },
  { title: 'Bac Pro SN', value: 'Bac Pro SN' },
  { title: 'Bac Pro GPPE', value: 'Bac Pro GPPE' },
  { title: 'Bac Pro MECP', value: 'Bac Pro MECP' },
  { title: 'Bac Pro Cuisine', value: 'Bac Pro Cuisine' },
  { title: 'Bac Pro Restauration', value: 'Bac Pro Restauration' },
  { title: 'Bac Pro Boucher-Charcutier-Traiteur', value: 'Bac Pro Boucher-Charcutier-Traiteur' },
];

export const degreeArabicOptions = arabicDegree;

export const degreeOptions = [
  {
    title: 'High school diploma',
    titleSlug: 'generator.high_school_diploma',
  },
  {
    title: 'GED',
    titleSlug: 'generator.ged',
  },
  {
    title: 'Associate of Arts',
    titleSlug: 'generator.associate_arts',
  },
  {
    title: 'Associate of Science',
    titleSlug: 'generator.associate_science',
  },
  {
    title: 'Associate of Applied Science',
    titleSlug: 'generator.associate_applied_science',
  },
  {
    title: 'Bachelor of Arts',
    titleSlug: 'generator.bachelor_arts',
  },
  {
    title: 'Bachelor of Science',
    titleSlug: 'generator.bachelor_science',
  },
  {
    title: 'BBA',
    titleSlug: 'generator.bba',
  },
  {
    title: 'Master of Arts',
    titleSlug: 'generator.master_arts',
  },
  {
    title: 'Master of Science',
    titleSlug: 'generator.master_science',
  },
  {
    title: 'MBA',
    titleSlug: 'generator.mba',
  },
  {
    title: 'J.D',
    titleSlug: 'generator.jd',
  },
  {
    title: 'LL.B',
    titleSlug: 'generator.llb',
  },
  {
    title: 'LL.M',
    titleSlug: 'generator.llm',
  },
  {
    title: 'M.D.',
    titleSlug: 'generator.md',
  },
  {
    title: 'Ph.D.',
    titleSlug: 'generator.phd',
  },
  {
    title: 'Bachelor of Technology',
    titleSlug: 'generator.bachelor_technology',
  },
  {
    title: "Master's Degree",
    titleSlug: 'generator.master_degree',
  },
  {
    title: 'MPhil',
    titleSlug: 'generator.mphil',
  },
  {
    title: 'MLitt',
    titleSlug: 'generator.mlitt',
  },
  {
    title: 'EdD',
    titleSlug: 'generator.edd',
  },
  {
    title: 'MBBS',
    titleSlug: 'generator.mbbs',
  },
  {
    title: 'Bachelor',
    titleSlug: 'generator.bachelor',
  },
  {
    title: 'Higher National Diploma',
    titleSlug: 'generator.higher_national_diploma',
  },
  {
    title: 'Higher National Certificate',
    titleSlug: 'generator.higher_national_certificate',
  },
  {
    title: 'Undergraduate',
    titleSlug: 'generator.undergraduate',
  },
  {
    title: 'Some College (no degree)',
    titleSlug: 'generator.some_college',
  },
].map((v) => ({ title: v.title, value: v.title, titleSlug: v.titleSlug }));

//object example { template: 'chicago', experiment: 'chicago_v3fix_lesscontact_experiment', version: 'v1' }
//version param is use for get appropriate client ( pushtell - v1, marvel - v2)

export const DATE_LANGUAGE_MAP = {
  hy: 'hy-am',
  no: 'nb',
};

export const REGION_MAP = {
  europe: [
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Åland Islands',
      code: 'AX',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Switzerland',
      code: 'CH',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Iceland',
      code: 'IC',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Moldova, Republic of',
      code: 'MD',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Holy See (Vatican City State)',
      code: 'VA',
    },
  ],
};
