/* eslint-disable import/order */
import React, { createContext, useContext, useState, useEffect } from 'react';
import ar from '../../../.cache/translations/ar.json';
import az from '../../../.cache/translations/az.json';
import bg from '../../../.cache/translations/bg.json';
import bn from '../../../.cache/translations/bn.json';
import ca from '../../../.cache/translations/ca.json';
import cs from '../../../.cache/translations/cs.json';
import da from '../../../.cache/translations/da.json';
import de from '../../../.cache/translations/de.json';
import el from '../../../.cache/translations/el.json';
import en from '../../../.cache/translations/en.json';
import es from '../../../.cache/translations/es.json';
import et from '../../../.cache/translations/et.json';
import fi from '../../../.cache/translations/fi.json';
import fr from '../../../.cache/translations/fr.json';
import he from '../../../.cache/translations/he.json';
import hi from '../../../.cache/translations/hi.json';
import hr from '../../../.cache/translations/hr.json';
import hu from '../../../.cache/translations/hu.json';
import hy from '../../../.cache/translations/hy.json';
import id from '../../../.cache/translations/id.json';
import is from '../../../.cache/translations/is.json';
import it from '../../../.cache/translations/it.json';
import ja from '../../../.cache/translations/ja.json';
import ka from '../../../.cache/translations/ka.json';
import kk from '../../../.cache/translations/kk.json';
import ko from '../../../.cache/translations/ko.json';
import lt from '../../../.cache/translations/lt.json';
import lv from '../../../.cache/translations/lv.json';
import ms from '../../../.cache/translations/ms.json';
import nl from '../../../.cache/translations/nl.json';
import no from '../../../.cache/translations/no.json';
import pl from '../../../.cache/translations/pl.json';
import pt from '../../../.cache/translations/pt.json';
import ptbr from '../../../.cache/translations/pt-br.json';
import ro from '../../../.cache/translations/ro.json';
import ru from '../../../.cache/translations/ru.json';
import sk from '../../../.cache/translations/sk.json';
import sl from '../../../.cache/translations/sl.json';
import sr from '../../../.cache/translations/sr.json';
import sv from '../../../.cache/translations/sv.json';
import th from '../../../.cache/translations/th.json';
import tj from '../../../.cache/translations/tj.json';
import tr from '../../../.cache/translations/tr.json';
import uk from '../../../.cache/translations/uk.json';
import uz from '../../../.cache/translations/uz.json';
import vi from '../../../.cache/translations/vi.json';
import zhcn from '../../../.cache/translations/zh-cn.json';
import tl from '../../../.cache/translations/tl.json';

const defaultLocale = 'en';
const translations = {
  en,
  fr,
  es,
  fi,
  id,
  ro,
  he,
  hu,
  hy,
  th,
  da,
  de,
  hr,
  nl,
  ar,
  el,
  sv,
  pt,
  ru,
  uk,
  ka,
  it,
  tr,
  vi,
  sr,
  is,
  sl,
  bg,
  sk,
  lv,
  no,
  pl,
  et,
  cs,
  lt,
  'pt-br': ptbr,
  ms,
  ja,
  ko,
  'zh-cn': zhcn,
  tj,
  az,
  ca,
  kk,
  hi,
  bn,
  uz,
  tl,
};

export const IntlContext = createContext();

// Context provider that supplies Intl state to child components
export function IntlProvider({ children, value }) {
  const intl = useIntlProvider(value);
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
}

// Provider hook that creates intl state and methods.
function useIntlProvider(lang) {
  const [locale, setLocale] = useState(lang);

  const [resumeLocale, setResumeLocale] = useState();
  useEffect(() => {
    resetToDefaultResumeLocale();
  }, []);
  const resetToDefaultResumeLocale = () => {
    if (!locale) {
      setResumeLocale('en');
      return;
    }
    switch (locale) {
      case 'th':
        setResumeLocale('en');
        break;
      case 'no':
        setResumeLocale('en');
        break;
      default:
        setResumeLocale(locale);
        break;
    }
  };
  // Update locale whenever new value comes from the provider
  useEffect(() => {
    if (Object.keys(translations).includes(lang)) {
      setLocale(lang);
    }
  }, [lang]);

  /**
   * Translation helper function to return the localized string.
   *
   * e.g. t('greeting') // "Bonjour"
   *
   * @param {string} key
   * @param {string} _locale - Locale to override the default (optional)
   */
  function t(key, _locale = locale) {
    // Null values aren't filtered by default value assignment
    // And we have a problem when null value comes through
    // When resume language/locale is not set, use app locale
    if (!_locale) {
      _locale = locale;
    }
    if (!translations[_locale]?.[key]) {
      console.warn(`Translation '${key}' for locale '${_locale}' not found.`);
    }
    const resp = translations[_locale]?.[key] || translations[defaultLocale]?.[key] || '';
    return resp.trim();
  }

  return {
    locale,
    setLocale,
    setResumeLocale,
    resumeLocale,
    resetToDefaultResumeLocale,
    t,
  };
}

// Decorator HOC for legacy class components that adds Intl context to children.
export const withIntl = (Component) =>
  class Wrapper extends React.Component {
    static async getInitialProps(ctx) {
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      return { ...pageProps };
    }

    render() {
      return <IntlContext.Consumer>{(state) => <Component {...this.props} {...state} />}</IntlContext.Consumer>;
    }
  };

// Component hook to access Intl methods.
const intlHook = () => useContext(IntlContext);

export default intlHook;
