import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getGTMID } from 'lib/helpers';

const GTM = ({ host }) => {
  const ID = getGTMID(host);
  if (!ID) return null;
  return (
    <Fragment>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${ID}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${ID}');
              `,
        }}
      />
    </Fragment>
  );
};

GTM.propTypes = {
  host: PropTypes.string,
};

export default GTM;
