import company_info from '../../../.cache/companyinfo/companyInfo.json';
import weekly_plan from '../../../.cache/specialplans/weekly.json';
// prettier-ignore
export const ERROR_MESSAGES = {
  required: 'error_required',
  email: 'error_email',
  numeric: 'error_numeric',
  phone: 'error_phone',
  zip: 'error_zip',
  chooseValue: 'error_choose_value',
  chooseRadio: 'error_choose_radio',
  chooseCheckbox: 'error_choose_checkbox',
  chooseAdultsOrKids: 'error_choose_adults_or_kids',
  jobRequired: 'error_job_required',
  employerRequired: 'error_employer_required',
  schoolRequired: 'error_school_required',
  requiredEmail: 'error_required_email',
  requiredJob: 'error_required_job',
  emailNotValid: 'error_email_not_valid',
  isEmailDuplicate: 'error_email_duplicate',
  emailNotFound: 'error_email_not_found',
  invalidUrl: 'error_invalid_url',
  isSlugDuplicate: 'duplicate_slug',
  emailPassowrdNotMatch: 'error_password_match'
};

export const TEST_DATE = new Date(1995, 11, 17, 12, 0, 0);
export const WEEKLY_POOL = weekly_plan;

export const JOB_TRACKING_HOST = ['cvdeboss', 'modeles-cv'];
export const ACCPTED_CARDS_LOGO = ['cbV7.png', 'masterCardV7.png', 'visaV7.png', 'americanExpressV7.png'];

export const COMPANY_INFO = company_info.companyInfo;

export const STORE_REDIRECT_HOST = 'resume-example';
